import "./Footer.scss";
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BaseImage from "../base-components/BaseImage";
import logo from "../../assets/logo/logo-DreamHomeW.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const currentYear = new Date().getFullYear();

  let activeStyle = {
    textDecoration: "underline",
  };

  return (
    <>
      <footer>
        <section className="footer-navbar-container">
          <Container>
            <Row>
              <Col md={12} lg={6}>
                <div className="logo-container">
                  <Link to={"/"} onClick={scrollToTop}>
                    <BaseImage
                      img={logo}
                      alt="Dream home footer logo"
                      width="50px"
                      height="50px"
                    />
                  </Link>
                  <p className="logo-text">
                    Oткрийте мечтаното <br /> <span></span>жилище с нас
                  </p>
                </div>
              </Col>
              <Col md={12} lg={6} className="d-flex align-items-center">
                <ul>
                  <li>
                    <NavLink
                      to="/real-estates"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      onClick={scrollToTop}
                    >
                      {" "}
                      Имоти{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      onClick={scrollToTop}
                    >
                      {" "}
                      Услуги{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about-us"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      onClick={scrollToTop}
                    >
                      {" "}
                      За нас{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contacts"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      onClick={scrollToTop}
                    >
                      {" "}
                      Контакти{" "}
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="copyright-container">
          <Container>
            <div className="copy">
              <p>
                © {currentYear} dreamhome19.bg | Всички права запазени. | Всички
                други лога или търговски марки са собственост на съответните им
                собственици.
              </p>
            </div>
          </Container>
        </section>
      </footer>
    </>
  );
};

export default Footer;
